export let propList = [
  { prop: 'id',label: 'ID', align: 'center', width: '80', webId: 1, fixed: 'left', },
  { prop: 'logo_path', label: 'Logo', align: 'center', minWidth: '40', slotName: 'logoSlot', showOverflowTooltip: true, webId: 2, },
  { prop: 'text_cn',label: '内容文本(中文)', align: 'center', minWidth: '200', showOverflowTooltip: true, copy: true, webId: 3 },
  { prop: 'text_us',label: '内容文本(英文)', align: 'center', minWidth: '200', showOverflowTooltip: true, width: '120', copy: true, webId: 4 },
  { prop: 'create_time', label: '创建时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 5 },
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '封面' },
    { id: 3, label: '内容文本(中文)' },
    { id: 4, label: '内容文本(英文)' },
    { id: 5, label: '创建时间' },
  ],
  width: 200, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: true //显示底部分页
}
